<template>
	<div class="activity_list">
		<div class="home_center">

			<div class="">
				<div class="center_box">
					<el-carousel indicator-position="outside">
					    <el-carousel-item v-for="item in banner" :key="item.banner_id">
					      <!-- <h3>{{ item }}</h3> -->
						  <el-image
						        style="width: 100%; height: 343px"
						        :src="item.banner_pic"
						        fit="cover"></el-image>
					    </el-carousel-item>
					  </el-carousel>
					<div class="center_list">
						<div class="list_title">
							<!-- 发现活动 -->{{$t('activity.FXHD')}}
						</div>
						<div class="list_menu">
							<div class="menu">
								<div><!-- 活动形式 -->{{$t('activity.HDXS')}}：</div>
								<el-select v-model="listData.event_type_method" :placeholder="$t('QXZ')" >
									<el-option v-for="item in eventType" :key="item.data_code" :label="item.data_value"
										:value="item.data_code">
									</el-option>
								</el-select>
							</div>
							<div class="menu">
								<span class="el-dropdown-link" >
								  <!-- 活动主题 -->{{$t('activity.HDZT')}}：
								</span>
								<el-select v-model="listData.theme_id" :placeholder="$t('QXZ')" >
									<el-option v-for="item in themeList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
								
							</div>
							<div class="menu" >
								<span class="el-dropdown-link" >
								  <!-- 排序方式 -->{{$t('activity.PXFS')}}：
								</span>
								
								  <el-select v-model="listData.event_sort_method" :placeholder="$t('QXZ')" >
								  	<el-option v-for="item in eventSort" :key="item.data_code" :label="item.data_value"
								  		:value="item.data_code">
								  	</el-option>
								  </el-select>
							</div>
							<div class="menu" style="margin-left: 10px;">
								<el-date-picker class="picker" v-model="listData.event_start_time" value-format="yyyy-MM-dd" :placeholder="$t('KSRQ')"></el-date-picker>
							</div>
							<div class="menu" style="width: 200px;margin-left: 10px;">
								
								<el-input  :placeholder="$t('SSHDMC')"  v-model="listData.keyword" clearable  style="color: #FFF;"> <i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
							</div>
							<div class="menu">
								<router-link to="/activity?status=2">
									<el-button type="primary" class="btn2">
										<img src="../../assets/img/biaoq.png" class="biaoq" alt="">
									</el-button>
								</router-link>
							</div>
							<div class="menu">
							<router-link to="/add_activity">
								<el-button type="primary" class="btn1"><!-- 发布活动 -->{{$t('add_activity.FBHD')}}</el-button>
							</router-link>
							</div>
						</div>
						<div class="list_box">
							<div class="box_obj"  style="cursor: pointer;" @click="toDetail(item.event_id)" v-for="(item,index) in list" :key="item.event_id">
								<div class="obj_img">
									<img :src="item.listpics[0]" class="img" alt="">
									<div class="img_box1" @click.stop="collect(item.event_id,index)">
										<img src="../../assets/img/like_fill.png" class="like" alt="" v-if="item.is_collect==1">
										<img src="../../assets/img/like2.png" class="like" alt="" v-if="item.is_collect==0">
										
									</div>
									<div class="img_box2" @click.stop="">
										<img src="../../assets/img/fengx.png" class="like" alt="">
										<div class="sharemenu">
											<div @click.stop="share(item.event_id,'facebook',item.event_name)">Facebook</div>
											<div @click.stop="share(item.event_id,'Linkedin',item.event_name)">LinkedIn</div>
											<div @click.stop="share(item.event_id,'twitter',item.event_name)">Twitter</div>
										</div>
									</div>
								</div>
								<div class="obj_name">
									{{item.event_name}}
								</div>
								<div class="obj_time">
									<!-- 活动时间 -->{{$t('activity.HDSJ')}}：{{$util.rTime(item.event_start_time)}}
								</div>
								<div class="obj_num">
									<div class="last">
										<img src="../../assets/img/like_fill.png" class="last_img" alt="">
										{{item.collect_count}}人
									</div>
									<div class="last">
										<img src="../../assets/img/user.png" class="last_img" alt="">
										{{item.join_num_people}}人
									</div>
								</div>
								
									<el-button type="primary" class="button">
										<!-- 参加 -->{{$t('activity.CJ')}}
									</el-button>
								
								
							</div>
						</div>
						
					</div>
				</div>
				
			</div>
			
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<!-- 回复弹窗 -->
		<!-- <Reply></Reply> -->
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	import scroll from '@/utils/scroll.js';
	import {debounce} from '@/utils/debounce.js';
	export default {
		components: {
			Homeright,
		},
		data() {
			return {
				input:'',
				listData:{
					theme_id:'',
					event_type_method:'',
					event_sort_method:'',
					event_start_time:'',
					keyword:'',					
					pageSize:15
				},
				currentPage:1,
				isLast:0,
				list:[],
				loading:false,
				
				eventType:[],
				eventSort:[],				
				themeList:[],
				banner:[]
			};
		},
		created() {
			this.getTheme();
			this.getUnit();
			this.getList();
			this.getBanner();
			scroll.scrollToBottom(this.getList);
		},
		
		computed:{
		    newlistData(){
		        return JSON.parse(JSON.stringify(this.listData));
		    }
		},
		watch:{
			newlistData:{
				handler:function(nval,oval){					
					for(let i in nval){
						if(nval[i]!=oval[i]){
							console.log(i);
							if(i=='keyword'){
								this.search();
								return;
							}
						}
					}
					this.getList('reload');
				},
				deep:true
			},
			
		},
		methods:{
			share:function(id,type,title){
				var href=this.$config.website+'activity_detail?event_id='+id;				
				this.$util.share(href,type,title);
			},
			toDetail(id){
				this.$router.push({path:'/activity_detail',query:{event_id:id}});
			},
			//收藏
			async collect(id,index){
				await this.$request.get('/api/event/collectEvent',{event_id:id});
				this.list[index].is_collect=this.list[index].is_collect==0?1:0;
			},
			//收藏
			async getBanner(){
				let res=await this.$request.get('/api/event/getBannerList');
				this.banner=res.data.records;
			},
			//参加
			async join(id,index){
				let res=await this.$request.get('/api/event/joinEvent',{event_id:id});
				this.list[index].is_join=this.list[index].is_join==0?1:0;
			},
			//获取主题
			async getTheme(){
				let res=await this.$request.get('/api/event/getAllThemeList');
				this.themeList=res.data.records;
				// this.themeList.unshift({value:'',label:'全部'});
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.eventType=res.data.event_type_method.data;
				this.eventSort=res.data.event_sort_method.data;
				// this.eventType.unshift({data_code:'',data_value:'全部'});
				// this.eventSort.unshift({data_code:'',data_value:'默认'});
			},
			search:debounce(function(e){
				console.log(e);
				this.getList('reload');
			}),
			//获取工作
			  async getList(t){
				if(t=='reload'){
					this.currentPage=1;
					this.isLast=0;			  	
				}
				if(this.isLast==1||this.loading){
					return;
				}
				console.log('加载数据');
				
				let data=JSON.parse(JSON.stringify(this.listData));
				data.currentPage=this.currentPage;
				this.loading=true;
				let res=await this.$request.post('/api/event/findPage',data);
				this.loading=false;
				if(t=='reload'){
					this.list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.list=[...this.list,...res.data.records];
					}
				}
				
				if(this.currentPage>=res.data.totalPage){
					this.isLast=1;
				}else{
					this.currentPage+=1;
				}
			  				
			  }
			
		}
	};
</script>

<style lang="less" >
	.activity_list {
		min-height: 100%;
		background-color: #212121;
		.home_center{
			display: flex;
			justify-content: flex-end;
		}
		.center_box{
			width: 1190px;
			color: #fff;
			width: 1190px;
			margin-right: 25px;
			background-color: #000000;
			.center_list{
				width: 1190px;
				padding: 25px;
				box-sizing: border-box;
				.list_title{
					height: 30px;
					line-height: 30px;
					border-bottom: 1px solid #567BB6;
					font-size: 17px;
					font-weight: bold;
				}
				.list_menu{
					padding: 16px 0;
					font-size: 14px;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					//justify-content: space-between;
					
					.menu{display: flex;align-items: center;margin-left: 30px;margin-bottom: 20px;
						&:first-child{margin-left: 0;}
						font-size: 14px;
						.picker{
							width: 100%;
						}
						.el-select{display: block;flex:1;}
					}
					
					.el-input__inner{
						background-color: #000000;
						//width: 110px;
						color:#fff;
						border-color:#567BB6;height: 38px;
					}
					.el-range-input{
						background-color: #000000;
					}
					.btn1{
						background-color: #567BB6;
						border: none;
					}
					.btn2{
						height: 38px;
						background-color: #567BB6;
						border: none;
						.biaoq{
							width: 15px;
							height: 15px;
						}
					}
				}
				.list_box{
					display: flex;
					flex-wrap: wrap;
					
					.box_obj{
						width: 270px;
						background-color: #16171C;
						border-radius: 7px;
						//overflow: hidden;
						margin-bottom: 20px;
						margin-right: 15px;
						.obj_img{
							margin-bottom: 10px;
							position: relative;
							.img_box1{
								position: absolute;
								top: 4px;
								right: 40px;
								z-index: 9;
								width: 28px;
								height: 28px;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: rgba(0, 0, 0, 0.5);
								border-radius: 5px;
								.like{
									width: 20px;
									height: 20px;
								}
							}
							.img_box2{
								position: absolute;
								top: 4px;
								right: 4px;
								z-index: 9;
								width: 28px;
								height: 28px;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: rgba(0, 0, 0, 0.5);
								border-radius: 5px;
								
								&:hover .sharemenu{display: block;}
								.like{
									width: 20px;
									height: 20px;
								}
							}
							.img{object-fit: cover;
								width: 270px;
								height: 180px;border-radius: 7px;
							}
						}
						.obj_name{
							color: #567BB6;
							font-size: 15px;
							margin: 10px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.obj_time{
							font-size: 12px;
							color: #fff;
							margin: 10px;
						}
						.obj_num{
							display: flex;
							align-items: center;
							font-size: 14px;
							margin: 10px;
							.last{
								margin-right: 10px;
								display: flex;
								align-items: center;
								.last_img{
									width: 17px;
									height: 17px;
									margin-right: 5px;
								}
							}
						}
						.button{
							width: 255px;
							height: 40px;
							background: #567BB6;
							border-radius: 7px;
							border: 1px solid #567BB6;
							display: flex;
							align-items: center;
							justify-content: center;
							margin: 10px;
						}
					}
					.box_obj:nth-child(4n){
						margin-right: 0px;
					}
				}
			}
		}
		.center_left {
			
			
			
		}
		
		
		.center_people {
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0;
			

		}
	}
</style>
